<script setup lang="ts">
import type { PropType } from 'vue';

type ButtonType = 'primary' | 'secondary' | 'warning';

const props = defineProps({
  type: {
    type: String as PropType<ButtonType>,
    default: 'primary',
  },
  text: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  withPillIndicator: {
    type: Boolean,
  },
});
</script>

<template>
  <button
    class="base-button relative flex flex-row items-center justify-center gap-2 rounded-full px-4 py-2"
    :class="{
      'bg-dark-purple text-white hover:bg-primary':
        !props.disabled && props.type === 'primary',
      'bg-red-400 text-white hover:bg-red-600':
        !props.disabled && props.type === 'warning',
      'border-[1px] border-primary text-primary hover:bg-primary hover:text-white':
        !props.disabled && props.type === 'secondary',
      'cursor-not-allowed bg-gray-300 hover:bg-gray-300 text-gray-500':
        props.disabled,
      'cursor-pointer': !props.disabled,
    }"
    :disabled="props.disabled"
  >
    <span
      v-show="withPillIndicator"
      class="absolute flex h-3 w-3 top-[-4px] right-[-3px]"
    >
      <span
        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75"
      ></span>
      <span
        class="relative inline-flex rounded-full h-3 w-3 bg-blue-500"
      ></span>
    </span>
    <BaseIcon
      v-if="props.isLoading"
      class="animate-spin"
      icon="mdi:loading"
      size="24"
    />
    <template v-else>
      <BaseIcon v-if="props.icon" :icon="props.icon" />
      <slot>
        <p class="text-sm">{{ props.text }}</p>
      </slot>
    </template>
  </button>
</template>
